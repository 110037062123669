import React, { useState, useContext, useEffect } from 'react';
import { XSquare } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { FileUpload } from '../infrastructure/FileUpload';

export const UploadFilePopup = ({ popup, fileType, dataId, onCloseClick }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);
    const [sku, setSku] = useState(null);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        const init = async () => {
            loader.show();
            var dataAccess = new ApiDataAccess('/api/sku');
            dataAccess.get(`/${dataId}`)
                .then(data => {

                    console.log(data.metaData);

                    setSku(data)
                    setMetaData(data.metaData);
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                })
        }
        init();
    }, []);

    const onUploadComplete = () => {

        popup.current.close();

        if (onCloseClick) {
            onCloseClick();
        }
    }

    return (
        <div>
            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>{fileType}</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            <div className='contentSection'>
                {metaData &&
                    <FileUpload key='upload1' orderNumber={sku.orderNumber} skuNumber={sku.skuNumber} onUploadComplete={() => { onUploadComplete() }} id={dataId} url={`/api/esko/${fileType}`} onArtworkResourceUploadFailed={() => { }} />
                }
            </div>

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal-block' onClick={() => { popup.current.close(); }}>Cancel</button>
            </div>
        </div>
    )
}
