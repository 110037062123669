import React, { useState, useRef } from 'react';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { BoundDropdownList, PageableList, BoundTextbox } from '../dataObjects/BoundControls';
import { Popup, Confirmation } from '../core/Popup';
import { XSquare, Trash, Edit } from 'react-feather';
import { Card } from '../core/Card';

export const DataStructureDefinitionView = () => {

    const context = useRef(null);
    const list = useRef(null);
    const popup = useRef(null);
    const confirmation = useRef(null);
    const [dataId, setDataId] = useState(null);

    const onNewClick = async () => {
        setDataId(null);
        popup.current.show(); 
    }

    const onEditClick = async (item) => {
        setDataId(item.id);
        popup.current.show(); 
    }


    const onDeleteClick = async (item) => {
        confirmation.current.show('Are you sure to delete this Data Structure Definition?', 'Delete', async () => {
            try {
                await context.current.deleteAsync(item.id);
            }
            catch (ex) {
                console.log(ex);                
                confirmation.current.show('This property can not be deleted as long other properties reference it.', 'Delete');
            }            
            list.current.refresh();
        });
    }

    return (
        <Card title='Data Structure Definitions' subtitle='Define here the data structure and dependencies to process from Esko Automation Engine'>               

            <Popup ref={popup}>
                <DataStructureDefinitionPopupContent popup={popup} onSaved={() => { list.current.refresh() }} dataId={dataId} />
            </Popup>

            <Confirmation ref={confirmation} />

            <DataObjectContainer ref={context} dataObjectName={'dataStructureDefinitionDataObject'} >

                <button className='btn btn-primary' onClick={() => { onNewClick()}}>New</button>

                <PageableList pageSize={20} ref={list}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Property</div>
                                <div className='cell'>Dependent Offset Property</div>
                                <div className='cell'>Data Type</div>
                                <div className='cell'>Feature Mapped</div>
                                <div className='cell'>Field Mapped</div>
                                <div className='cell'>Required</div>
                                <div className='cell small'>&nbsp;</div>
                                <div className='cell small'>&nbsp;</div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className='row' key={`row${key}`}>
                                <div className='cell'>{item.propertyName}</div>
                                <div className='cell'><span>{item.dependendPropertyName !== null ? references.dataSources.dependendPropertyName.find(e => e.id == item.dependendPropertyName).name : '--'}</span></div>
                                <div className='cell'><span>{item.dataType !== null ? references.dataSources.dataType.find(e => e.id == item.dataType).name : '--'}</span></div>
                                <div className='cell'><span>{item.featureMapped !== null && item.featureMapped >= 0 ? references.dataSources.featureMapped.find(e => e.id == item.featureMapped).name : '--'}</span></div>
                                <div className='cell'><span>{item.fieldMapped !== null && item.fieldMapped >= 0 ? references.dataSources.fieldMapped.find(e => e.id == item.fieldMapped).name : '--'}</span></div>
                                <div className='cell'><span>{item.required !== null ? references.dataSources.required.find(e => e.id == item.required).name : '--'}</span></div>
                                <div className='cell small'><Edit className='icon clickable pull-right' onClick={() => { onEditClick(item) }} /></div>
                                <div className='cell small'><Trash className='icon clickable pull-right' onClick={() => { onDeleteClick(item) }} /></div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>
        </Card>
    )
}



export const DataStructureDefinitionPopupContent = ({ popup, dataId, onSaved }) => {

    const context = useRef(null);
    const [valid, setValid] = useState(false);

    const onSaveClick = async () => {
        popup.current.close();
        var response = await context.current.submitAsync();
        if (onSaved) {
            onSaved(response);
        }
    }

    return (
        <DataObjectContainer ref={context} dataObjectName={'dataStructureDefinitionDataObject'} dataObjectId={dataId} onValidated={valid => { setValid(valid) }} >

            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>New Data Structure Definition</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            <div className='contentSection'>
                <label htmlFor='propertyName'>Property Name</label>
                <BoundTextbox propertyName={'propertyName'} />

                <label htmlFor='dependendPropertyName'>Dependend Offset Property Name</label>
                <BoundDropdownList propertyName={'dependendPropertyName'} caption={'None'} />

                <label htmlFor='dependendPropertyName'>Data Type</label>
                <BoundDropdownList propertyName={'dataType'} />

                <label htmlFor='dependendPropertyName'>Feature Mapped</label>
                <BoundDropdownList propertyName={'featureMapped'} />

                <label htmlFor='dependendPropertyName'>Field Mapped</label>
                <BoundDropdownList propertyName={'fieldMapped'} />

                <label htmlFor='dependendPropertyName'>Required</label>
                <BoundDropdownList propertyName={'required'} />

            </div>

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>Cancel</button>
                <button className='btn btn-secondary btn-modal' disabled={!valid} onClick={onSaveClick}>Save</button>
            </div>
        </DataObjectContainer>
    );
}
